html,
body,
#root {
    height: 100%;
    margin: 0;
    overflow: hidden !important;
}

* {
    overscroll-behavior: none;
}

::-webkit-scrollbar {
    width: 7px !important;
    height: 7px;
    background: transparent;
    // background: #202330;
    // background: #eaeaea;
}

::-webkit-scrollbar-thumb {
    background: #a5a5a536;
    border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
    background: #8a8989;
}

section {
    background-color: #ffffff;
}

iframe {
    // width: 100% !important;
    // height: 100% !important;
    border: none !important;
    user-select: none;
}

#egamings_container {
    width: 100% !important;
    height: 100% !important;
}

.react-responsive-modal-root {
    z-index: 10002 !important;
    .react-responsive-modal-overlay {
        // background: white !important;
        background: rgba(0, 0, 0, 0.1) !important;
        opacity: 0.5;
    }
    .react-responsive-modal-modal {
        background: #272a37;
        position: relative;
        z-index: 1200;
        outline: unset;
        border-radius: 8px;
        @media (max-width: 768px) {
            margin: 0;
        }
        .react-responsive-modal-closeButton {
            // display: none;
            svg {
                fill: rgb(216, 216, 216);
            }
            &:focus-visible {
                outline: unset !important;
            }
        }
    }
}

.MuiTabs-scrollButtons {
    color: white;
    svg {
        width: 1.5em;
        height: 1.5em;
    }
}

//.MuiTabs-indicator {
//    background: #fa0 !important;
//}
//
//.MuiLinearProgress-bar {
//    background: #fa0 !important;
//}

//.MuiInputBase-root-MuiOutlinedInput-root {
//    color: white !important;
//}
//
//input:-webkit-autofill {
//    -webkit-text-fill-color: white !important;
//}

//.MuiPopover-paper {
//    background: #202330 !important;
//    border: 1px solid rgba(215, 215, 215, 0.1);
//}

.MuiPopover-root {
    position: fixed !important;
    height: 100vh;
}

.MuiMenuItem-root {
    img {
        width: 24px;
    }
}

.MuiModal-root {
    z-index: 10000 !important;
}

.Toastify__toast-container {
    z-index: 99999 !important;
}

#menu- {
    z-index: 9999;
}
div[role="dialog"] {
    z-index: 9999;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.MuiCalendarPicker-root {
    div,
    button,
    span {
        font-family: PT Sans !important;
    }
}
.MuiMenuItem-root {
    font-family: PT Sans !important;
}

#callbell-iframe {
    user-select: none !important;
    z-index: 101 !important;
    @media (max-width: 1000px) {
        bottom: 55px !important;
    }
}

//input:-webkit-autofill,
//input:-webkit-autofill:hover,
//input:-webkit-autofill:focus,
//input:-webkit-autofill:active {
//    -webkit-box-shadow: 0 0 0 30px #272a37 inset;
//    color: rgb(216, 216, 216);
//}

#praxisModal {
    max-width: 922px;
    width: 922px;
    //background: #272a37 !important;
    box-shadow: none !important;
    padding-top: 1rem !important;
    iframe {
        width: 100%;
        height: 700px;
    }
    @media (max-width: 1024px) {
        width: 100%;
        // height: 100%;
        padding: 1rem 0 0 0 !important;
    }
}

.MuiTooltip-popper {
    z-index: 10001;
}
.MuiTooltip-tooltip {
    margin-left: 0 !important;
}

.MuiDateRangeCalendar-root>div:first-child {
    display: none !important;
}